<template>
  <CRow>
    <CCol>
      <!--UPLOAD-->
      <form
        enctype="multipart/form-data"
        novalidate
        v-if="isInitial || isSaving"
      >
        <!-- <h4>Upload images</h4> -->
        <div class="dropbox">
          <input
            type="file"
            multiple
            :name="uploadFieldName"
            :disabled="isSaving"
            @change="
              filesChange($event.target.name, $event.target.files);
              fileCount = $event.target.files.length;
            "
            accept="image/*"
            class="input-file"
          />
          <p v-if="isInitial">
            Drag your file(s) here to begin<br />
            or click to browse
          </p>
          <p v-if="isSaving">Uploading {{ fileCount }} files. Please wait...</p>
        </div>
      </form>
      <!--SUCCESS-->
      <div v-if="isSuccess">

        <!-- <ul class="list-unstyled">
          <li v-for="item in uploadedFiles">
            <img
              :src="item.url"
              class="img-responsive img-thumbnail"
              :alt="item.originalName"
            />
          </li>
        </ul> -->
        <p>
          <a href="javascript:void(0)" @click="reset()"
            >Click to upload again</a
          >
        </p>      </div>
      <!--FAILED-->
      <div v-if="isFailed">
        <p>Added failed.</p>
        <p>
          <a href="javascript:void(0)" @click="reset()">Try again</a>
        </p>
        <pre>{{ uploadError }}</pre>
      </div>
    </CCol>
  </CRow>
</template>

<script>
// swap as you need
//import { upload } from './file-upload.fake.service'; // fake service
//import { uploadImage } from "../../lib/fileUploadApi"; // real service
import FileUploadApi from "../../lib/fileUploadApi"; // real service

const STATUS_INITIAL = 0,
  STATUS_SAVING = 1,
  STATUS_SUCCESS = 2,
  STATUS_FAILED = 3;

export default {
  name: "WidgetsUploadImage",
  data() {
    return {
      uploadedFiles: [],
      uploadError: null,
      currentStatus: null,
      uploadFieldName: "image",
      fileUploadApi: new FileUploadApi(),
    };
  },
  computed: {
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING;
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS;
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED;
    },
  },
  methods: {
    reset() {
      // reset form to initial state
      this.currentStatus = STATUS_INITIAL;
      this.uploadedFiles = [];
      this.uploadError = null;
    },
    save(formData) {
      // upload data to the server
      this.currentStatus = STATUS_SAVING;
      this.fileUploadApi
        .uploadDocument(formData)
        .then((x) => {
          this.uploadedFiles = [].concat(x.result);
          this.currentStatus = STATUS_SUCCESS;
          this.$emit("uploaded", {
            uploadedFiles: this.uploadedFiles,
          });
        })
        .catch((err) => {
          this.uploadError = err.response;
          this.currentStatus = STATUS_FAILED;
        });

      //      this.currentStatus = STATUS_SAVING;

      //   uploadImage(formData)
      //     // .then(wait(1500)) // DEV ONLY: wait for 1.5s
      //     .then((x) => {
      //       console.log(x.result);
      //       this.uploadedFiles = [].concat(x.result);
      // //      console.log(this.uploadedFiles)
      //       this.currentStatus = STATUS_SUCCESS;

      //       this.$emit("uploaded", {
      //         uploadedFiles: this.uploadedFiles,
      //       });
      //     })
      //     .catch((err) => {
      // //      console.log("failed");

      //       this.uploadError = err.response;
      //       this.currentStatus = STATUS_FAILED;
      //     });
    },

    filesChange(fieldName, fileList) {
      // handle file changes
      const formData = new FormData();

      if (!fileList.length) return;

      // append the files to FormData
      Array.from(Array(fileList.length).keys()).map((x) => {
        formData.append(fieldName, fileList[x], fileList[x].name);
      });

      // save it
      this.save(formData);
    },
  },
  mounted() {
    this.reset();
  },
};
</script>

<style lang="scss">
.dropbox {
  outline: 2px dashed grey; /* the dash box */
  outline-offset: -10px;
  background: lightcyan;
  color: dimgray;
  padding: 10px 10px;
  min-height: 200px; /* minimum height */
  position: relative;
  cursor: pointer;
}

.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 200px;
  position: absolute;
  cursor: pointer;
}

.dropbox:hover {
  background: lightblue; /* when mouse over to the drop zone, change color */
}

.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 50px 0;
}
</style>